const initialState = {
  isMessageDialogOpen: false,
  errorMessageI18n: ''
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_MESSAGE_DIALOG_OPEN':
    return {
      ...state,
      isMessageDialogOpen: action.payload
    }
  case 'SET_ERROR_MESSAGE_I18N_KEY':
    return {
      ...state,
      errorMessageI18n: action.payload
    }
  default:
    return state
  }
}

export default rootReducer
