import './src/styles/global.css'
import './src/styles/tailwind.css'
import wrapWithProvider from './wrap-with-provider'

export const onRouteUpdate = ({ prevLocation }) => {
  // globally register previous route
  if (prevLocation?.pathname) {
    window.previousPath = prevLocation.pathname
  }
}

export const wrapPageElement = wrapWithProvider
