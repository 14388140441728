import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'

// 创建 Redux store
const createStore = () =>
  configureStore({
    reducer: rootReducer
  })

export default createStore
